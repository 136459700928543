.lottery-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.lottery-container::before {
    content: "";
    background-image: url('./img/logo.png');
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    opacity: 0.1; 
    z-index: 0;
    width: 150px;
    height: 150px;
    pointer-events: none;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    z-index: 1;
    position: relative;
}

.input-group label {
    margin-bottom: 5px;
    text-align: left;
}

input[type="number"] {
    padding: 5px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    color: #000000;
    box-sizing: border-box;
    z-index: 1;
    position: relative;
}

input[type="number"]:focus {
    outline: none;
    border-color: #4A90E2;
}

button.draw-button, button.export-button, button.reset-button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #4A90E2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1;
    position: relative;
}

button.draw-button:hover, button.export-button:hover, button.reset-button:hover {
    background-color: #357ABD;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.winner-list {
    margin-top: 20px;
    animation: fadeIn 1s ease-in;
    z-index: 1;
    position: relative;
}

.winner-item {
    margin: 5px 0;
    padding: 10px;
    background-color: #e0ffe0;
    border-radius: 4px;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
